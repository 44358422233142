import { QuestionnaireType } from './questionnaire-type';
import { User } from '../../auth/entities/user';
import { isAfter, isBefore } from 'date-fns';

export class MyMedaxQuestionnaireSubmission {
    uuid: string;
    created_at: string;
    updated_at: string;
    title?: string;
    concernedUser?: User;
    concernedUsername: string;
    answererUser?: User;
    answererUsername?: string;
    questionnaire: any;
    questionnaireType?: QuestionnaireType;
    pdfContent?: string;
    myMedaxQuestionnaireId?: string;
    scoring?: any;
    marker?: string;
    concernedUserPseudonym?: string;
    submissionVersioningUuid?: string;
}

export interface ExtendedSubmission extends MyMedaxQuestionnaireSubmission {
    description: string;
}

export class QuestionnaireSubmissionVersioning {
    questionnaireSubmissions: MyMedaxQuestionnaireSubmission[];
    versionUuid: string;
    latestCreation: string;
    firstCreation: string;

    constructor(submission: MyMedaxQuestionnaireSubmission) {
        this.questionnaireSubmissions = [submission];
        this.versionUuid = submission.submissionVersioningUuid;
        this.latestCreation = submission.created_at;
        this.firstCreation = submission.created_at;
    }

    sortSubmissionsAscending(): void {
        this.questionnaireSubmissions.sort((a, b) => {
            if (isBefore(new Date(a.created_at), new Date(b.created_at))) {
                return -1;
            } else if (isAfter(new Date(a.created_at), new Date(b.created_at))) {
                return 1;
            }
            return 0;
        });
    }

    sortSubmissionsDescending(): void {
        this.questionnaireSubmissions.sort((a, b) => {
            if (isBefore(new Date(a.created_at), new Date(b.created_at))) {
                return 1;
            } else if (isAfter(new Date(a.created_at), new Date(b.created_at))) {
                return -1;
            }
            return 0;
        });
    }
}
